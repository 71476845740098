import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'gatsby';
import { activationUser } from '../../components/Auth/Utils';
import withLocation from '../../components/shared/withLocation';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEOPage';
import styled from '@emotion/styled';
import { wrapper } from '../../utils/style';
import Parallax from '../../components/shared/Backgrounds/Parallax';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }
`;

const ActivationUserTemplate = ({search, navigate }) => {

  const homePageUrl = process.env.FRONTEND_PROTOCOL + '://' + process.env.FRONTEND_DOMAIN;
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [activationError, setActivationError] = useState(false);

  const activateUser = useCallback(() => {
    if(search && (search.action === 'activation') && search.email && search.code) {
      activationUser('', search, setRedirectToHome, setActivationError);
      setRedirectToHome(true);
    }
  }, [search]);


  useEffect(() => {
    if (!redirectToHome) {
      activateUser();
    }
    const timer = setTimeout(() => {
      navigate(homePageUrl)
    }, 5000);
    return () => clearTimeout(timer);
    //setTimeout(navigate(homePageUrl), 3000);
    //navigate(homePageUrl);
  }, [activateUser, navigate, redirectToHome, homePageUrl]);

  return (
    <>
      {activationError && <Layout>
        <SEO title="Account Activation" description="Info on your Lottoasian account activation"/>
        <Parallax/>
        <Wrapper>
          <h1 className="gradient">มีข้อผิดพลาดในการเปิดใช้งาน:</h1>
          <p>{activationError}</p>
          <Link to="/" className="button">คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก</Link>
        </Wrapper>
      </Layout>}
      {!activationError && !redirectToHome && <Layout>
        <Parallax/>
        <Wrapper>
          <h1 className="gradient">กำลังเปิดใช้งานบัญชี ...</h1>
        </Wrapper>
      </Layout>}
    </>
  );
};

export default withLocation(ActivationUserTemplate);
